<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="text-h5" v-if="action === 'Single Upload'"
        >Upload Single Document</v-card-title
      >
      <v-card-title class="text-h5" v-else
        >Upload Multiple Documents</v-card-title
      >
      <v-card-text
        ><p class="dialog-content">
          Please select the document(s) you wish to upload.
        </p>
        <v-form ref="uploadDocForm">
          <v-file-input
            label="User document"
            @change="selectFile($event)"
            ref="singleFileInput"
            outlined
            v-if="action === 'Single Upload'"
          ></v-file-input>
          <v-file-input
            label="User document"
            @change="selectFiles($event)"
            ref="multipleFileInput"
            multiple
            chips
            outlined
            v-else
          ></v-file-input>
        </v-form>
        <!-- <input type="file" @change="selectFile1($event)" /> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="uploadDocument"
          v-if="action === 'Single Upload'"
        >
          Upload</v-btn
        >
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="uploadDocuments"
          v-else
        >
          Upload</v-btn
        >
        <v-btn color="grey darken-1" dark @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog', 'action', 'user'],
  data: () => ({
    loading: false,
    userFile: [],
    multipleFiles: [],
  }),
  methods: {
    resetFiles() {
      this.$refs.uploadDocForm.reset()
      if (this.action === 'Single Upload') {
        this.$refs.singleFileInput.$refs.input.value = null
      } else {
        this.$refs.multipleFileInput.$refs.input.value = null
      }
    },
    close() {
      this.$emit('refresh')
      this.$emit('update:dialog', false)
      this.resetFiles()
    },
    selectFile(file) {
      this.userFile = file
    },
    selectFiles(files) {
      this.multipleFiles = files
    },
    async uploadDocument() {
      this.loading = true
      let formData = new FormData()
      formData.append('file', this.userFile)
      formData.append('user_id', this.user.user_id)
      try {
        let response = await this.$store.dispatch('upload_single_doc', formData)
        this.resetFiles()
        this.$root.snackbar.show({
          message: response.data.message,
          color: 'success',
        })
        this.close()
      } catch (error) {
        if (error && error.response) {
          this.$root.snackbar.show({
            message: error.response.data.message,
            color: 'error',
          })
        } else {
          this.$root.snackbar.show({
            message: error.response,
            color: 'error',
          })
        }
      } finally {
        this.loading = false
      }
    },
    async uploadDocuments() {
      this.loading = true
      let formData = new FormData()
      this.multipleFiles.forEach((file, index) => {
        formData.append(`file${index + 1}`, file)
      })
      // formData.append('user_id', this.user.user_id)
      try {
        let response = await this.$store.dispatch('upload_docs', formData)
        this.resetFiles()
        console.log(response.data.message)
        this.$root.snackbar.show({
          message: response.data.message,
          color: 'success',
        })
        this.close()
      } catch (error) {
        console.log(error)
        if (error && error.response) {
          this.$root.snackbar.show({
            message: error.response.data.message,
            color: 'error',
          })
        } else {
          this.$root.snackbar.show({
            message: error.response,
            color: 'error',
          })
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  font-size: 16px;
}
</style>